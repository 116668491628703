import React from 'react'
import { useHasMounted } from '../utils'
import { TrackLeft } from '../components/TrackLeft'
import { TrackRight } from '../components/TrackRight'

const partners = [
  {
    logo: '404.png',
    website: 'https://404dao.io',
  },
  {
    logo: 'hackquest.svg',
    website: 'https://hackquest.io',
  },
  {
    logo: 'moonshot2.svg',
    website: 'https://moonshotcollective.space',
  },
  {
    logo: 'sx.png',
    website: 'https://startup.exchange',
  }
]

const trackData = [
  {
    image: 'https://payload.web3atl.io/media/full-arbitrum-logo.png',
    link: 'https://arbitrum.io',
    title: 'Stylus Track',
    description: 'Arbitrum stylus is a tool that allows developers to build and deploy smart contracts on Arbitrum using familiar languages like Rust, C++, and C.',
    difficulties: {
      'Blockchain': 2,
      'Smart Contracts': 3,
      'Rust, C++, or C': 3,
    }
  },
  {
    image: 'https://media.graphassets.com/pyb441HiSOW35ZlFJiFs',
    link: 'https://404dao.io',
    title: 'Solidity Sprint',
    description: 'On November 4th, hackers will compete in a Solidity Sprint competition with problems that increase in difficulty. The goal is to achieve the highest score by interacting with a live contract on Goerli testnet.',
    difficulties: {
      'Blockchain': 2,
      'Solidity': 3,
      'Game Theory': 1,
    }
  },
  {
    image: 'https://payload.web3atl.io/media/Chainlink_Logo_Blue.svg.png',
    link: 'https://chain.link',
    title: 'Chainlink Track',
    description: 'Chainlink is vital to Web3. Hackers will build projects that utilize Chainlinks cross chain messaging protocol, oracles, and more.',
    difficulties: {
      'Blockchain': 2,
      'Solidity': 2,
      'Oracles': 1,
    }
  },
  {
    image: 'https://payload.web3atl.io/media/Untitled design (55)-2.png',
    title: 'Revest Finance',
    link: 'https://revest.finance',
    description: 'Utilizing Revests financial NFTs, hackers will build projects that integrate with Revests smart contract templates to create a new DeFi product.',
    difficulties: {
      'Solidity': 2,
      'DeFi': 2,
    }
  }
]

const faqs = [
  {
    question: 'What are the team sizes?',
    answer: 'Teams are capped to at most 4 participants'
  },
  // {
  //   question: 'Where do I submit a team?',
  //   answer: 'Use <b><u><a href="https://forms.gle/a8oWcWnYxt3ZhyAj7" target="_blank">this link</a></u></b> to submit teams'
  // },
  // {
  //   question: 'How will the prizes be distributed?',
  //   answer:
  //     'Each sponsor outlines prize distribution according to the details included in the <b><u><a href="https://docs.google.com/document/d/1SKRlqt5lIM3IOvXQeupBlJwITV3hggryKK9T0wXo9fk/edit?usp=sharing" target="_blank">hack pack</a></u></b>'
  // },
  {
    question: 'When is the hackathon?',
    answer: 'Begins October 27th with a virtual kick-off and ends November 5th in-person!'
  },
  {
    question: 'Where is the hackathon located?',
    answer: 'The hackathon will be virtual until November 3rd in which it will be in-person at Tech Square'
  },
]

export function Home() {
    
    const hasMounted = useHasMounted(1000)
  
    const extra = `z-10 transition-all ease-out duration-1000 delay-1000 drop-shadow ${hasMounted ? 'opacity-100' : 'opacity-0 translate-y-4'}`
    return (
      <>
        {/* First fold */}
        <section className="text-white z-20 flex max-w-screen-2xl mx-auto justify-center md:justify-start items-center min-h-screen drop-shadow">
          <div className="max-w-[min(600px,100vw)] px-4 md:px-16 md:mx-20 lg:mx-36 xl:mx-48">
            <h3 className={`font-monument-light text-3xl font-extrabold  transition-all duration-1000 ease-out ${hasMounted ? 'opacity-75' : 'opacity-0 translate-y-4'}`}>
              Web3ATL
            </h3>
            <h1
              className={`font-monument text-4xl md:text-6xl font-black drop-shadow-2xl transition-all duration-1000 delay-500 ease-out ${
                hasMounted ? '' : 'opacity-0 translate-y-4'
              }`}>
              Hackathon.
            </h1>
            <div className={extra}>
              <p className={`mb-3 text-gray-400`}>
                💻 Hybrid Event.
                <br />
                🤝 Open to all. <br />
                💸 <b>$10,000+ in prizes.</b>
              </p>
              <p className="uppercase text-white font-bold mb-2 ">📍 Oct 27 - Nov 5, Tech Square</p>
              <p className="uppercase text-white font-bold mb-8 ">🚨 Applications close Oct 26th</p>
              <div className={`grid md:grid-cols-3 gap-4 text-center`}>
                <a href="https://app.buidlbox.io/404-dao/web3-atl-hackathon" className="cta-button" target="_blank">
                  Apply
                </a>
                <a href="https://t.me/+DGAYthvO3UJmYzYx" className="cta-button" target="_blank">
                  Telegram
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Hackathon Partners */}
        <section className="z-20 drop-shadow backdrop-blur-2xl border-y border-white/20 backdrop-brightness-50">
          <h2 className="font-monument-bold text-xl text-center mt-2 -mb-4md:-mb-8 text-white">Presented By</h2>
          <div className="flex w-full justify-center align-middle items-center mx-auto">
            <div className='grid grid-cols-2 md:flex md:flex-row justify-center items-center gap-x-8 md:gap-x-4 px-4 md:px-0'>
                {partners.map((partner) => (
                  partner.logo.startsWith('sx') ?
                    <a href={partner.website} target='_blank' className='w-24 md:w-36 h-6 md:h-12 flex hover:cursor-pointer'>
                      <img src={`/${partner.logo}`} className='w-full h-full' />
                    </a>
                    :
                    <a href={partner.website} target='_blank' className=' w-24 h-24 md:w-40 md:h-40 flex hover:cursor-pointer'>
                      <img src={`/${partner.logo}`} className='w-24 h-24 md:w-40 md:h-40' />
                    </a>
                ))}
            </div>
          </div>
        </section>

        {/* Tracks */}
        {
          trackData.map((track, i) => {
            return (
              i % 2 == 0 ? <TrackLeft track={track} key={track.title} /> : <TrackRight track={track} key={track.title} />
            )
          })
        }
      
        {/* FAQ */}
        <section className="section-thing blur-and-dark !h-auto py-20">
          <div className="w-full space-y-10">
            <div className="flex flex-col md:flex-row w-full justify-around px-8 gap-6 min-w-[300px] mx-auto drop-shadow">
              <a href="https://app.buidlbox.io/404-dao/web3-atl-hackathon" className="button" target="_blank">
                Apply
              </a>
              {/* <a
                href="https://docs.google.com/document/d/1SKRlqt5lIM3IOvXQeupBlJwITV3hggryKK9T0wXo9fk/edit?usp=sharing" 
                target="_blank"
                className="button">
                Hack Pack
              </a> */}
              <a href="https://calendar.google.com/calendar/u/0/embed?src=c_a17c38c75798836434d6b68d3d99e82a25359e8859f2c08c00f4cf80f500d44e%40group.calendar.google.com&ctz=America%2FNew_York" className="button" target="_blank">
                Schedule
              </a>
              <a href="https://t.me/+DGAYthvO3UJmYzYx" className="button" target="_blank">
                Telegram
              </a>
              <a href="https://discord.gg/G7ZV5kFmd2" className="button" target="_blank">
                Discord
              </a>
            </div>
            <div className="px-10 md:px-16 lg:px-20 xl:px-24">
              <h2 className="mb-2 mt-4 font-monument-bold text-4xl">FAQs</h2>
              <div className="faq">
                {faqs.map((faq, i) => (
                  <>
                    <h3 className="font-primary text-2xl" key={i}>
                      {faq.question}
                    </h3>
                    <p key={faq.question} className="text-gray-300 pl-2 pb-4" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                  </>
                ))}
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
