import React, { Suspense } from 'react'
import { Outlet } from "react-router-dom";
import Effects from './Effects'
import MetaballSwarm from './MetaballSwarm/MetaballSwarm'
import { getDevicePixelRatio } from "./utils"
import { Canvas } from 'react-three-fiber'
import { useHasMounted } from './utils'

export function Layout() {
    const hasMounted = useHasMounted(1500);
    return (
      <>
        <meta
          property="og:image"
          content="https://cdn.raster.app/blockchain-at-georgia-tech/Sb4iAtiu4B?ixlib=js-3.6.0&amp;s=ef4c765f8a020ebbb89567b2d2d73076"></meta>
        <div style={{ height: '100vh' }} className="fixed w-full pointer-events-none">
          <div className="flex w-full h-full bg-black opacity-80"> 
            <iframe
              src='https://my.spline.design/w3acube-42523de71c2779f8312db917dda47bfc/' 
              className={"hidden z-[-999] md:block w-[100vw] h-[100vh] md:translate-x-72 lg:translate-x-72 xl:translate-x-72 transition-opacity duration-1000 " + (hasMounted ? 'opacity-100' : 'opacity-0')}
            >
            </iframe>
          </div>
        </div>
        <div className="block w-[100vw] h-[100vh] fixed md:hidden">
          <Canvas gl={{ autoClear: false }} pixelRatio={getDevicePixelRatio(1)}>
            <MetaballSwarm />
            <Suspense fallback={null}>
              <Effects />
            </Suspense>
          </Canvas>
        </div>
  
        <Outlet/>
      </>
    )
  }