export function DifficultySlider({ num, title }) {
    const max = 4
    return (
      <div key={title+num} className="flex flex-row w-full items-center">
        <div className="flex w-1/2 gap-x-1">
          <p className="mr-2 font-mono justify-start">{title}</p>
        </div>
        
        <div className="flex flex-row gap-1 justify-start">
          {new Array(max).fill(0).map((_, i) => {
            return <div className={`rounded-full w-2 h-2 border border-white/90 ${i < num ? 'bg-white' : ''}`} key={i}></div>
          })}
        </div>
      </div>
    )
  }