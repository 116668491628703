import { DifficultySlider } from "./DifficultySlider";

export function TrackRight({ track }) {
    return (
        <section className="section-thing md:gap-8">
          <a href={track.link} className="hover:cursor-pointer w-[min(500px,90vw)] block md:hidden">
              <img src={track.image} alt="" className="drop-shadow grayscale-0 brightness-100 contrast-100 saturate-100 md:brightness-200 md:contrast-200 md:grayscale md:saturate-200 md:hover:grayscale-0 md:hover:brightness-100 md:hover:contrast-100 md:hover:saturate-100" />
          </a>
          <div className="glass w-[min(500px,90vw)]">
            <h2 className=" font-monument-light text-3xl font-bold">{track.title}</h2>
            <div className="opacity-80 my-4">
              {track.description}
              <br/>
              <br/>
                {
                    Object.keys(track.difficulties).map((difficulty, i) => {
                        return (
                            <DifficultySlider num={track.difficulties[difficulty]} title={difficulty} key={track+difficulty}/>
                        )
                    })
                }
            </div>
            {/* <a href="https://docs.google.com/document/d/17xHAAFSGQvqFVjnnMepil4Jwwq2iCHX2RQSQ3SnozX0/edit?usp=sharing" className="button" target="_blank">
              Problem Details
            </a> */}
          </div>
          <a href={track.link} className="hover:cursor-pointer w-[min(500px,90vw)] hidden md:block">
            <img src={track.image} alt="" className="drop-shadow brightness-200 contrast-200 grayscale saturate-200 hover:grayscale-0 hover:brightness-100 hover:contrast-100 hover:saturate-100" />
          </a>
        </section>
    )
}